<template>
  <div class="bg-pattern min-h-screen text-forgd-primary-900 font-display">
    <slot />
  </div>
  <UNotifications />
</template>

<style scoped>
.bg-pattern {
  background-color: #ededea;
  background-image: url('/patterns/onboarding.svg');
}
</style>
